import type { BlockViewProps } from '@plone/types';
import { Image } from '@plone/volto/components';
import { Link, Text } from '../../Typography';
import type { RelatedItem } from '@plone/types/src/content/common';

type ExtendedTeaserData = {
  title: string;
  head_title?: string;
  hide_description: boolean;
  description: string;
  preview_image: undefined | { image_field: string }[];
  href:
    | undefined
    | { '@id': string; hasPreviewImage: boolean; image_field: string }[];
  relatedLinks: RelatedItem[];
};
export const Extended = ({
  data,
  isEditMode,
}: BlockViewProps & { isEditMode: boolean; data: ExtendedTeaserData }) => {
  const href = data.href?.[0];
  const image = data.preview_image?.[0];

  const relatedLinks = data.relatedLinks;
  const LinkComponent = isEditMode ? Text : Link;
  return (
    <div className="teaser extended-teaser">
      {href && (
        <>
          <div className="teaser-item default">
            {(href.hasPreviewImage || href.image_field || image) && (
              <div className="image-wrapper">
                <Image
                  item={image || href}
                  imageField={image ? image.image_field : href.image_field}
                  alt=""
                  loading="lazy"
                  responsive={true}
                />
              </div>
            )}
            <div className="teaser__content">
              {data?.head_title && <Text size="h2">{data.head_title}</Text>}

              <LinkComponent
                openLinkInNewTab={false}
                href={href['@id']}
                size="h3"
                className="headline"
              >
                {data?.title}
              </LinkComponent>
              {!data.hide_description && (
                <Text size="sm">{data?.description}</Text>
              )}
              {relatedLinks && (
                <div className="teaser__related-links">
                  {relatedLinks.map((item) => (
                    <Link
                      openLinkInNewTab={false}
                      href={undefined}
                      item={item}
                      size="sm"
                    >
                      {item.title}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
