import Arrow from '../../../theme/components/arrow_right.svg';
import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';

type ServiceViewProps = {
  content: {
    blocks: any;
    title: string;
    description: string;
  };
};

const ServiceView = ({ content }: ServiceViewProps) => {
  return (
    <div id="page-document" className="service">
      <div className="to-summary">
        <button>
          <img src={Arrow} alt="arrow" />
        </button>
        <p>Naar het overzicht</p>
      </div>
      <div className="service-content">
        <div className="service-left">
          <RenderBlocks content={content} />
        </div>
        <div className="service-right"></div>
      </div>
    </div>
  );
};

export default ServiceView;
