/* eslint-disable jsx-a11y/anchor-is-valid */
import Container from '@kitconcept/volto-light-theme/components/Atoms/Container/Container';
import LOGO from '../../assets/logo_flanders.svg';
import LOGO_DARK from '../../assets/logo_flanders_dark.svg';

export const FlandersFooter = () => {
  return (
    <div className="flanders-footer footer">
      <Container layout className="flanders-footer__container">
        <img
          src={LOGO}
          alt="Vlaanderen logo"
          className="flanders-footer__logo desktop"
        />
        <img
          src={LOGO_DARK}
          alt="Vlaanderen logo"
          className="flanders-footer__logo mobile"
        />
        <div className="flanders-footer__content">
          <h2>
            Vlaamse Milieumaatschappij is een officiële website van de Vlaamse
            overheid
          </h2>
          <p>
            uitgegeven door <a href="#">VMM</a>
          </p>
          <div className="flanders-footer__links">
            <a href="/privacy">Pivacy</a>
            <a href="/cookie-policy">Cookieverklaring</a>
            <a href="/toegankelijkheid">Toegankelijkheid</a>
          </div>
        </div>
        <div className="flanders-footer__languages">
          <a href="#">NL</a>
          <a href="#">EN</a>
        </div>
      </Container>
    </div>
  );
};
