import React from 'react';
import { SidebarPortal, BlockDataForm } from '@plone/volto/components';
import View from './View';
import type { BlockEditProps } from '@plone/types';

const schema = {
  title: 'Tag Social',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: [],
    },
  ],
  properties: {},
  required: [],
};

const Edit = (props: BlockEditProps) => {
  const { onChangeBlock, block, data, selected } = props;

  return (
    <>
      <View {...props} isEditMode />
      <SidebarPortal selected={selected}>
        <BlockDataForm
          schema={schema}
          title={schema.title}
          onChangeField={(
            id: keyof typeof data,
            value: (typeof data)[keyof typeof data],
          ) => {
            onChangeBlock(block, {
              ...data,
              [id]: value,
            });
          }}
          onChangeBlock={onChangeBlock}
          formData={data}
          block={block}
        />
      </SidebarPortal>
    </>
  );
};
export default Edit;
