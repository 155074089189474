import { ContentFooter } from './ContentFooter';
import { FlandersFooter } from './FlandersFooter';
import { SupportFooter } from './SupportFooter';

export const Footer = () => {
  return (
    <footer id="footer">
      <SupportFooter />
      <ContentFooter />
      <FlandersFooter />
    </footer>
  );
};
