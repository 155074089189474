import {
  ComponentProps,
  SyntheticEvent,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';

type Props = { numberOfPages: number } & ComponentProps<typeof Grid>;

export const Carousel = ({
  numberOfPages,
  children,
  className,
  ...props
}: Props) => {
  const carousel = useRef<HTMLDivElement | null>(null);
  const [selected, setSelected] = useState(0);

  const onPageClick = useCallback((i: number) => {
    const carouselEl = carousel.current;
    if (!carouselEl) return;

    const grid = carouselEl.querySelector<HTMLDivElement>('div.grid');

    if (!grid) return;

    const rect = grid.getBoundingClientRect();

    grid.scrollTo({
      left: i * rect.width,
      behavior: 'smooth',
    });
  }, []);

  const updateScroll = useCallback(
    (element: HTMLElement) => {
      const rect = element.getBoundingClientRect();

      const newSelected = Math.round(element.scrollLeft / rect.width);
      setSelected(newSelected);
    },
    [setSelected],
  );

  useLayoutEffect(() => {
    const carouselEl = carousel.current;
    if (!carouselEl) return;

    const grid = carouselEl.querySelector<HTMLDivElement>('div.grid');

    if (!grid) return;

    updateScroll(grid);
  }, [updateScroll]);

  return (
    <div className="carousel" ref={carousel}>
      <Grid
        {...props}
        className={cx(className)}
        onScroll={(e: SyntheticEvent<HTMLElement>) =>
          updateScroll(e.currentTarget)
        }
      >
        {children}
      </Grid>

      <div className="carousel__pages">
        {Array(numberOfPages)
          .fill(null)
          .map((_, i) => (
            <button
              onClick={() => onPageClick(i)}
              key={i}
              className={cx(
                'carousel__page',
                i === selected && 'carousel__page--selected',
              )}
            />
          ))}
      </div>
    </div>
  );
};
