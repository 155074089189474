import type { BlockViewProps } from '@plone/types';
import { ConditionalLink, Image } from '@plone/volto/components';
import { Link, Text } from '../../Typography';
import { Box } from '../../Box';
import { useBreakpoint } from '../../../helpers/useMedia';
import { Button } from '../../Button';
type defaultTeaserData = {
  title: string;
  head_title?: string;
  hide_description: boolean;
  description: string;
  preview_image: undefined | { image_field: string }[];
  createdAt: string;
  href:
    | undefined
    | {
        '@id': string;
        hasPreviewImage: boolean;
        image_field: string;
        created: string;
      }[];
};
export const StandaloneTeaser = ({
  data,
  isEditMode,
}: BlockViewProps & { data: defaultTeaserData; isEditMode: boolean }) => {
  const href = data.href?.[0];
  const image = data.preview_image?.[0];

  const Wrapper = isEditMode ? Box : Link;

  const isBigScreen = useBreakpoint('md');

  const hasLink = href && !!href.length;
  let link = hasLink ? (
    href.length > 0 && (
      <ConditionalLink item={data.href[0]} to={null} condition={!isEditMode}>
        <Button size="large" textColor="white" color="tertiary" icon>
          Lees meer
        </Button>
      </ConditionalLink>
    )
  ) : (
    <Button size="large" textColor="white" color="tertiary" icon>
      Lees meer
    </Button>
  );

  return (
    <div className="teaser standalone-teaser default-teaser">
      {href && (
        <Wrapper
          openLinkInNewTab={false}
          href={href['@id']}
          className="teaser-item default"
          style={
            !isBigScreen ? { display: 'flex', flexDirection: 'column' } : {}
          }
        >
          {(href.hasPreviewImage || href.image_field || image) && (
            <div
              className="image-wrapper"
              style={isBigScreen ? { maxWidth: '50%' } : {}}
            >
              <Image
                item={image || href}
                imageField={image ? image.image_field : href.image_field}
                alt=""
                loading="lazy"
                responsive={true}
              />
            </div>
          )}
          <div
            className="teaser__content"
            style={isBigScreen ? { marginLeft: '50px' } : {}}
          >
            {data?.title && <Text size="h2">{data.title}</Text>}
            {!data.hide_description && (
              <Text size="sm" weight="400">
                {data?.description}
              </Text>
            )}
            {link}
          </div>
        </Wrapper>
      )}
    </div>
  );
};
