import { BlockContainer } from '../BlockContainer';
import { Graph } from '../Graph';

type Props = {
  content: {
    json_config: any;
  };
};

export const GraphTemplate = (props: Props) => {
  const options = props.content.json_config;
  return (
    <BlockContainer className="graph">
      <Graph options={options} />
    </BlockContainer>
  );
};
