import type { BlockViewProps, RelatedItem } from '@plone/types';
import { BlockContainer } from '../../BlockContainer';
import { Graph } from '../../Graph';
import { useEffect, useMemo, useState } from 'react';

export const GraphBlockView = (
  props: BlockViewProps & {
    data: { target: null | (RelatedItem & { json_config: any }) };
  },
) => {
  const target = props.data.target;

  const [fetchError, setFetchError] = useState(false);
  const [jsonConfig, setJsonConfig] = useState(null);

  const isGraph = target && target['@type'] === 'Graph';
  useEffect(() => {
    if (!target || !isGraph) return;

    const id = target['@id'].replace('/@@download/excel', '');
    fetch(
      `${id.replace(
        window.location.host,
        `${window.location.host}/++api++`,
      )}/@@display-file/json_config`,
    )
      .then((r) => r.json())
      .then((r) => {
        if (r.type === 'Unauthorized') {
          setJsonConfig(null);
          setFetchError(true);
        } else {
          setJsonConfig(r);
          setFetchError(false);
        }
      })
      .catch(() => {
        setJsonConfig(null);
        setFetchError(true);
      });
  }, [target, isGraph]);

  const error = useMemo(() => {
    if (!isGraph) return 'De geselecteerde content is geen grafiek.';
    if (fetchError)
      return 'Er ging iets mis bij het ophalen van de grafiekdata.';
  }, [fetchError, isGraph]);
  return (
    <BlockContainer className="graph">
      {jsonConfig && <Graph options={jsonConfig} />}

      {error && <p className="error">{error}</p>}
    </BlockContainer>
  );
};
