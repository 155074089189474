import { HTMLAttributes } from 'react';
import cx from 'classnames';
import Container from '@kitconcept/volto-light-theme/components/Atoms/Container/Container';

type Props = {
  noMaxWidth?: boolean;
} & HTMLAttributes<HTMLDivElement>;
export const BlockContainer = ({
  noMaxWidth,
  className,
  children,
  ...props
}: Props) => {
  return (
    <div
      {...props}
      className={cx(
        'block-container',
        noMaxWidth && 'block-container--no-max-width',
        className,
      )}
    >
      <Container layout className="">
        {children}
      </Container>
    </div>
  );
};
