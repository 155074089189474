import type { BlockViewProps, RelatedItem } from '@plone/types';
import { Component } from '@plone/volto/components';

export const Default = (
  props: BlockViewProps & {
    data: { href: RelatedItem[] };
    isEditMode: boolean;
  },
) => {
  const target = props.data.href && props.data.href[0];
  const dependency = target ? target['@type'] : null;

  if (!target && props.isEditMode)
    return <div className="teaser teaser--empty-edit" />;

  return (
    <Component
      componentName="TeaserBody"
      dependencies={[dependency]}
      {...props}
    />
  );
};
