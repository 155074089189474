import type { BlockViewProps, RelatedItem } from '@plone/types';
import { Grid } from 'semantic-ui-react';
import { useBreakpoint } from '../../../helpers/useMedia';
import { Carousel } from '../../Carousel';
import { Default } from '../Teaser/Default';

type ListingItem = RelatedItem;
export const TeaserListingTemplate = ({
  items,
  styles,
  blocks_layout,
  ...props
}: BlockViewProps & {
  '@type': string;
  items: ListingItem[];
  blocks_layout: { items: any[] };
  styles?: { gridCutoff: 2 | 3 | 4 };
  isEditMode: boolean;
}) => {
  const isMobile = !useBreakpoint('md');

  const GridComponent = isMobile ? Carousel : Grid;

  return (
    <div className="teaser-listing__items">
      <GridComponent
        numberOfPages={items.length}
        stackable
        stretched
        columns={styles?.gridCutoff || 3}
      >
        {items.map((item) => (
          <Grid.Column className="vmm-listing__item">
            <div className="block teaser">
              <Default
                {...props}
                content={
                  {
                    '@type': props['@type'],
                    styles: {
                      gridCutoff: styles?.gridCutoff || 3,
                    },
                  } as any
                }
                data={{
                  '@type': 'teaser',
                  href: [item],
                  description: item.description,
                  title: item.title,
                }}
              />
            </div>
          </Grid.Column>
        ))}
      </GridComponent>
    </div>
  );
};
