import React from 'react';
import PropTypes from 'prop-types';
import { ConditionalLink } from '@plone/volto/components';
import './SummaryTemplate.scss';
import { format } from 'date-fns';

import { Button } from '../../../../../../../../addons/volto-hoofdsite/src/components/Button';
const SummaryTemplate = ({ items, isEditMode, buttonLink }) => {
  return (
    <>
      <div className="items">
        {items?.map((item) => {
          return (
            <div className="list-item" key={item['@id']}>
              <ConditionalLink item={item} condition={!isEditMode}>
                <div className={'newsItem'}>
                  {item?.Date && (
                    <p className="newsItem-date">
                      {format(item.Date, 'dd MMMM yyyy')}
                    </p>
                  )}
                  <div className="listing-body">
                    <h3>{item.title ? item.title : item.id}</h3>
                    <svg
                      width="8"
                      height="13"
                      viewBox="0 0 8 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M1 1L6.15 6.15C6.19783 6.19489 6.23595 6.24911 6.26201 6.30931C6.28807 6.3695 6.30152 6.4344 6.30152 6.5C6.30152 6.5656 6.28807 6.6305 6.26201 6.69069C6.23595 6.75089 6.19783 6.80511 6.15 6.85L1 12"
                        stroke="#234855"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                </div>
              </ConditionalLink>
            </div>
          );
        })}
        <ConditionalLink item={buttonLink} to={null} condition={!isEditMode}>
          <Button size="large" textColor="white" color="border" icon>
            Bekijk alle Nieuws
          </Button>
        </ConditionalLink>
      </div>
    </>
  );
};

SummaryTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkMore: PropTypes.any,
  isEditMode: PropTypes.bool,
};

export default SummaryTemplate;
