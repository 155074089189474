import type { BlockViewProps, RelatedItem } from '@plone/types';
import { Grid } from 'semantic-ui-react';
import cx from 'classnames';
import { CardTeaser } from '../Teaser/Card';

type ListingItem = RelatedItem;
export const CardListingTemplate = ({
  items,
  styles = {},
  isEditMode,
}: BlockViewProps & {
  isEditMode: boolean;
  items: ListingItem[];
  blocks_layout: { items: any[] };
  styles: Partial<{ gridCutoff: 2 | 3 | 4; solid: boolean }>;
}) => {
  return (
    <div className="basic-listing__items">
      <Grid
        numberOfPages={items.length}
        stackable
        stretched
        columns={styles?.gridCutoff || 3}
      >
        {items.map((item) => (
          <Grid.Column
            className={cx('basic-listing__item', {
              'basic-listing__item--solid': styles.solid,
            })}
          >
            <CardTeaser
              isEditMode={isEditMode}
              data={{
                title: item.title,
                description: item.description,
                styles: styles,
                href: [item],
              }}
            />
          </Grid.Column>
        ))}
      </Grid>
    </div>
  );
};
