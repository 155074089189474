import React from 'react';

import { Button } from '../../../Button';
import downloadSvg from '../../../../assets/downloadPdf.svg';

type PublicationTeaserProps = {
  image: string;
  download: string;
  teaser_alt: string;
  publication_date: Date;
};

const PublicationTeaser = ({
  image,
  download,
  teaser_alt,
  publication_date,
}: PublicationTeaserProps) => {
  return (
    <div className="publication-teaser">
      <img src={image} alt={teaser_alt} className="publication-image" />
      <a href={download}>
        <Button color="tertiary" size="medium">
          Download pdf
          <img alt="Download" src={downloadSvg} />
        </Button>
      </a>
    </div>
  );
};

export default PublicationTeaser;
