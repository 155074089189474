import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';

type NieuwsberichtViewProps = {
  content: {
    title: string;
    description: string;
    image: {
      download: string;
    };
    effective: string;
    '@type': string;
  };
};

const NieuwsberichtView = ({ content }: NieuwsberichtViewProps) => {
  return (
    <div id="page-document" className="nieuwsbericht">
      <RenderBlocks content={content} />
    </div>
  );
};

export default NieuwsberichtView;
