import SnelNaarBlockView from './View';
import SnelNaarBlockEdit from './Edit';

import icon from '@plone/volto/icons/right-key.svg';

export const snelnaarblock = {
  id: 'snelnaarblock',
  title: 'Snel naar block',
  edit: SnelNaarBlockEdit,
  view: SnelNaarBlockView,
  icon: icon,
  group: 'common',
  restricted: false,
  mostUsed: false,
  sidebarTab: 1,
  security: {
    addPermission: [],
    view: [],
  },
};
