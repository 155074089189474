import Container from '@kitconcept/volto-light-theme/components/Atoms/Container/Container';
import { socials } from '../Blocks/Socials/socials';

type Props = {};

const sitemap = {
  content: [
    {
      title: 'Feiten & cijfers',
      links: [
        ['Water', '/feiten-cijfers/water'],
        ['Lucht', '/feiten-cijfers/lucht'],
        ['Klimaat', '/feiten-cijfers/klimaat'],
        ['Kaarten & cijfers', '#'],
      ],
    },
    {
      title: 'Beleid',
      links: [
        ['Luchtbeleid', '/beleid/luchtbeleid'],
        ['Waterbeleid', '/beleid/waterbeleid'],
        ['Klimaatadaptie', '/beleid/klimaatadaptatie'],
      ],
    },
    {
      title: 'Diensten & producten',
      links: [
        ['Burgers', '#'],
        ['Lokale overheden', '#'],
        ['Bedrijven', '#'],
      ],
    },
    {
      title: 'Projecten',
      links: [
        ['Lokale projecten', '#'],
        ['Europese projecten', '#'],
        ['Projectenoproepen', '#'],
      ],
    },
    {
      title: 'Tips',
      links: [
        ['Milieu- en besparingtips', '#'],
        ['Handige tools', '#'],
        ['Campagnes', '#'],
      ],
    },
  ],
  misc: [
    ['Nieuws', '/nieuws'],
    ['Evenementen', '/evenementen'],
    ['Over VMM', '/over-vmm'],
    ['Jobs', 'jobs'],
    ['Publicaties', '/publicaties'],
    ['Pers', '/pers'],
    ['Contact', '/contact'],
  ],
};
export const ContentFooter = (props: Props) => {
  return (
    <div className="footer">
      <Container layout className="footer__container">
        <div className="footer__branding">
          <div className="footer__branding-logo">
            <h2>
              Vlaamse
              <br />
              <b>Milieumaatschappij</b>
            </h2>

            <p>
              Onze leefomgeving klimaatbestendig maken?
              <br />
              Daarvoor zetten we samen met partners in op een duurzaam lucht-,
              water- en klimaatbeleid.
            </p>
          </div>
          <div className="footer__socials">
            <h4>Volg VMM op sociale media</h4>
            <div>
              {socials.map(([src, href, size]) => (
                <a href={href} key={src}>
                  <img
                    src={src}
                    style={{
                      width: size,
                      height: size,
                    }}
                    alt="Social logo"
                  />
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="footer__sitemap">
          <div className="footer__content-links">
            {sitemap.content.map(({ title, links }) => (
              <div className="column" key={title}>
                <h4>{title}</h4>
                {links.map(([label, href]) => (
                  <a href={href} key={label}>
                    {label}
                  </a>
                ))}
              </div>
            ))}
          </div>
          <div className="footer__hr" />
          <div className="footer__links">
            {sitemap.misc.map(([label, href]) => (
              <a href={href} key={label}>
                {label}
              </a>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ContentFooter.propTypes = {
  /**
   * i18n object
   */
};
