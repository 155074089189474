import { Indicator as IndicatorType } from '../Blocks/IndicatorHeader/indicators';
import cx from 'classnames';

type Props = {
  indicator: IndicatorType;
  vertical?: boolean;
  dark?: boolean;
};

export const Indicator = ({ dark, indicator, vertical }: Props) => {
  const classNames = cx('indicator', {
    'indicator--vertical': vertical,
    'indicator--dark': dark,
  });

  return (
    <div className={classNames}>
      <p className="indicator__name">{indicator.name}</p>
      <div className="indicator__content">
        <img
          className="indicator__icon"
          alt={indicator.name}
          src={(dark ? indicator.iconDark : indicator.icon) || undefined}
        />
        <div>
          <p className="indicator__status">
            <span
              className="indicator__status-color"
              style={{ background: indicator.color }}
            />
            {indicator.status}
          </p>
          {indicator.futureStatus && (
            <p className="indicator__future-status">
              <span>Morgen:</span> {indicator.futureStatus}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
