import { Text } from '../../Typography';
import type { BlockViewProps, RelatedItem } from '@plone/types';
import { useBreakpoint } from '../../../helpers/useMedia';
import { Image } from '@plone/volto/components';
import { format } from 'date-fns';
import SummaryTemplate from '../../../customizations/@kitconcept/volto-light-theme/components/Listing/SummaryTemplate';
import './WhatsNewTemplate.scss';
import { TagSocial } from '../../TagSocial';

export const WhatsNewTemplate = ({
  items,
  spotlightItem,
  buttonLink,
  isEditMode,
}: BlockViewProps & {
  items: RelatedItem[];
  blocks_layout: { items: any[] };
  spotlightItem: [RelatedItem];
  isEditMode: boolean;
}) => {
  let data = null;
  let buttonLinkData = null;
  if (spotlightItem && spotlightItem.length >= 1) {
    data = spotlightItem[0];
  }
  if (buttonLink && buttonLink.length >= 1) {
    buttonLinkData = spotlightItem[0];
  }
  const isBigScreen = useBreakpoint('lg');

  return (
    <>
      <div
        className={`wat-is-er-nieuw-block ${isBigScreen ? 'big-screen' : ''}`}
      >
        <div>
          <Image item={data} className="mainArticleImg" />
          <p>{data?.Date && format(data?.Date, 'dd MMMM yyyy')}</p>
          <Text size="h2" className="headline">
            {data?.title}
          </Text>
          <Text size="md" weight="400">
            {data?.description}
          </Text>
          <TagSocial
            showSocials={false}
            isEditMode={isEditMode}
            tags={['Nieuws', 'Lucht']}
          />
        </div>
        <div className="summary-container">
          <SummaryTemplate items={items} buttonLink={buttonLinkData} />
        </div>
      </div>
    </>
  );
};
