import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { UniversalLink } from '@plone/volto/components';

import { H5 } from '../../Typography';
import PartnerLogo from './PartnerLogo';

type ProjectViewProps = {
  content: {
    title: string;
    description: string;
    projectstatus: { title: string };
    looptijd: string;
    budget: string;
    financiering: string;
    projectverantwoordelijke: string;
    locatie: string;
    url: string;
    logos: {
      token: string;
    }[];
  };
};

const ProjectView = ({ content }: ProjectViewProps) => {
  var urlhost = '';
  try {
    var projecturl = new URL(content?.url);
    urlhost = projecturl?.hostname;
  } catch (e) {}
  return (
    <div id="page-document" className="project">
      <div className="project-flex">
        <div className="project-left">
          <RenderBlocks content={content} />
        </div>
        <div className="project-right">
          <div className="right-side-section">
            <div className="partners">
              {content?.logos?.map((logo) => (
                <PartnerLogo partner={logo.token} />
              ))}
            </div>
          </div>
          <div className="right-side-section">
            <H5>Project details</H5>
            <div className="details">
              {content?.projectstatus?.title && (
                <div className="detail-item">
                  <p>Status</p>
                  <p className="project-status">
                    {content?.projectstatus?.title}
                  </p>
                </div>
              )}
              {content?.looptijd && (
                <div className="detail-item">
                  <p>Looptijd</p>
                  <p>{content?.looptijd}</p>
                </div>
              )}
              {content?.budget && (
                <div className="detail-item">
                  <p>Budget</p>
                  <p>{content?.budget}</p>
                </div>
              )}
              {content?.financiering && (
                <div className="detail-item">
                  <p>Financiering</p>
                  <p>{content?.financiering}</p>
                </div>
              )}
              {content?.projectverantwoordelijke && (
                <div className="detail-item">
                  <p>Projectverandwoordelijke</p>
                  <p>{content?.projectverantwoordelijke}</p>
                </div>
              )}
              {content?.locatie && (
                <div className="detail-item">
                  <p>Locatie</p>
                  <p>{content?.locatie}</p>
                </div>
              )}
              {content?.url && (
                <div className="detail-item">
                  <p>URL</p>
                  {urlhost.length > 0 ? (
                    <UniversalLink openLinkInNewTab={true} href={content?.url}>
                      {urlhost}
                    </UniversalLink>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectView;
