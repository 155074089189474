import type { RelatedItem } from '@plone/types';
import { Link } from '../../Typography';
import rightSvg from '@plone/volto/icons/right-key.svg';
import { Icon } from '@plone/volto/components';

type Props = {
  item: undefined | RelatedItem;
};
export const ReadMoreLink = (props: Props) => {
  return (
    <Link
      className="teaser__read-more"
      item={props.item}
      href={undefined}
      openLinkInNewTab={false}
    >
      Lees meer
      <Icon name={rightSvg} size="24px" />
    </Link>
  );
};
