import { LanguageSelector } from '@plone/volto/components';
import './MobileHeaderVlaanderen.scss';
const MobileHeaderVlaanderen = () => {
  return (
    <div className="tools-wrapper">
      <div id="headerVlaanderen" style={{ padding: '20px 0 10px 0' }}>
        <p id="headerTitleVlaanderen">Vlaanderen</p>

        <svg
          width="9"
          height="23"
          viewBox="0 0 9 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            x1="0.469897"
            y1="0.829128"
            x2="8.4699"
            y2="22.8291"
            stroke="#D2D8DF"
          />
        </svg>

        <p id="headerUrlVmm">www.vmm.be</p>
      </div>

      <LanguageSelector />
    </div>
  );
};

export default MobileHeaderVlaanderen;
