import { Grid } from 'semantic-ui-react';
import cx from 'classnames';
import { RenderBlocks } from '@plone/volto/components';
import { withBlockExtensions } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import type { BlockViewProps, RelatedItem } from '@plone/types';
import { useBreakpoint } from '../../../helpers/useMedia';
import { Carousel } from '../../Carousel';
import { Link } from '../../Typography';

const GridBlockView = (
  props: BlockViewProps & {
    data: {
      headlineLink?: [RelatedItem];
      headlineLinkLabel: string;
      headline: string;
      blocks_layout: { items: any[] };
      mobileCarousel: boolean;
      styles: { gridCutoff: 2 | 3 | 4 };
    };
  },
) => {
  const { data, path, className } = props;
  const metadata = props.metadata || props.properties;
  const blocksConfig =
    config.blocks.blocksConfig[data['@type']].blocksConfig ||
    props.blocksConfig;
  const location = {
    pathname: path,
  };
  const columns = data.styles.gridCutoff;

  const isMobile = !useBreakpoint('md');

  const GridComponent = data.mobileCarousel && isMobile ? Carousel : Grid;

  return (
    <div className={cx('block', data['@type'], className)}>
      <div className="block-header">
        {data.headline && <h2 className="headline">{data.headline}</h2>}

        {data.headlineLink && (
          <Link
            openLinkInNewTab={false}
            href={null}
            item={data?.headline?.[0]}
            size="sm"
          >
            {data.headlineLinkLabel || data.headlineLink[0].title}
            {' >'}
          </Link>
        )}
      </div>
      <GridComponent
        numberOfPages={Object.keys(props.data.blocks_layout.items).length}
        stackable
        stretched
        columns={columns}
      >
        <RenderBlocks
          {...props}
          blockWrapperTag={Grid.Column}
          metadata={metadata}
          content={data}
          location={location}
          blocksConfig={blocksConfig}
          isContainer
        />
      </GridComponent>
    </div>
  );
};

export default withBlockExtensions(GridBlockView);
