import { BlockDataForm, SidebarPortal } from '@plone/volto/components';
import { IndicatorHeader } from './DefaultIndicatorHeader';
import type { BlockEditProps } from '@plone/types';
import { indicators } from './indicators';
import type { RelatedItem } from '@plone/types/src/content/common';

const schema = {
  title: 'Indicator Header',

  fieldsets: [
    {
      id: 'default',
      title: 'Default',
      fields: ['title', 'subTitle', 'indicators', 'showWarnings'],
    },
    {
      id: 'call-to-action',
      title: 'Call to action',
      fields: ['button', 'link'],
    },
    {
      id: 'look',
      title: 'Look',
      fields: ['image'],
    },
  ],

  properties: {
    image: {
      title: 'Achtergrond',
      widget: 'object_browser',
      mode: 'image',
      return: 'single',
    },
    title: {
      title: 'Titel',
    },
    subTitle: {
      title: 'Subtitel',
      widget: 'textarea',
    },
    button: {
      title: 'Knop label',
    },
    link: {
      title: 'Knop link',
      widget: 'object_browser',
      mode: 'link',
      return: 'single',
    },
    indicators: {
      title: 'Indicators',
      isMulti: true,
      choices: indicators.map((i) => [i, i]),
      widget: 'select',
    },
    showWarnings: {
      title: 'Toon waarschuwingen',
      type: 'boolean',
    },
  },

  required: ['title', 'subTitle', 'indicators'],
};

export type IndicatorHeaderBlockData = {
  '@type': string;
  image: null | [RelatedItem];
  indicators: null | string[];
  title: string;
  subTitle: string;
  showButton: boolean;
  showWarnings: boolean;
  button: string;
  link: RelatedItem;
};

export const IndicatorHeaderEdit = (
  props: BlockEditProps & {
    data: { variation: string } & IndicatorHeaderBlockData;
  },
) => {
  const { selected, block, onChangeBlock, data } = props;

  const variation = props.data.variation;
  const View =
    props.blocksConfig[props.data['@type']].variations?.find(
      (v) => v.id === variation,
    )?.template || IndicatorHeader;

  return (
    <>
      <View {...props} />
      <SidebarPortal selected={selected}>
        <BlockDataForm
          title={schema.title}
          schema={schema}
          onChangeField={(
            id: keyof typeof data,
            value: (typeof data)[keyof typeof data],
          ) => onChangeBlock(block, { ...data, [id]: value })}
          onChangeBlock={onChangeBlock}
          formData={data}
          block={block}
        />
      </SidebarPortal>
    </>
  );
};
