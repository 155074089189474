import { Warning } from '../Blocks/IndicatorHeader/indicators';

type Props = {
  warnings: Warning[];
};
export const Warnings = ({ warnings }: Props) => {
  return (
    <div className="warnings">
      <h5>Waarschuwingen:</h5>
      {warnings.map((warning, index) => (
        <a href={warning.href} key={index}>
          <img src={warning.icon} alt="Waarschuwingen icoon" />
          {warning.label}
        </a>
      ))}
    </div>
  );
};
