import RenderBlocks from '@plone/volto/components/theme/View/RenderBlocks';
import { H5 } from '../../Typography';

type AgendaProps = {
  content: {
    title: string;
    description: string;
    preview_image: {
      download: string;
    };
    preview_caption: string;
    location: string;
    start: string;
  };
};

const Agenda = ({ content }: AgendaProps) => {
  return (
    <div id="page-document" className="agenda">
      <div className="agenda-flex">
        <div className="agenda-left">
          <RenderBlocks content={content} />
        </div>
        <div className="agenda-right">
          <div className="right-side-section">
            <H5>Praktische informatie</H5>
            <div className="practical-info">
              <p>Datum</p>
              <p>
                {new Intl.DateTimeFormat('nl-NL', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                }).format(new Date(content?.start))}
              </p>
            </div>
            <div className="practical-info">
              <p>Locatie</p>
              <p>{content?.location}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Agenda;
