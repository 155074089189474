// SemanticUI-free pre-@plone/components
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Container from '@kitconcept/volto-light-theme/components/Atoms/Container/Container';
import MobileNavigation from '../MobileNavigation/MobileNavigation';
import Navigation from '../Navigation/Navigation';
import { useIntl, defineMessages } from 'react-intl';
import config from '@plone/volto/registry';
import cx from 'classnames';
import { useBreakpoint } from '../../../../helpers/useMedia';
import InternetHeaderVlaanderen from './InternetHeader/InternetHeaderVlaanderen';
import MobileHeaderVlaanderen from './MobileHeader/MobileHeaderVlaanderen';
import { Link } from 'react-router-dom';
import { UniversalLink } from '@plone/volto/components';

const messages = defineMessages({
  siteLabel: {
    id: 'siteLabel',
    defaultMessage: ' ',
  },
});

const InternetHeader = ({ pathname }) => {
  const isBigScreen = useBreakpoint('lg');
  return (
    <div className="header">
      {isBigScreen && <InternetHeaderVlaanderen />}
      {!isBigScreen && <MobileHeaderVlaanderen />}
      <Container layout>
        <div
          className="logo-nav-wrapper"
          style={
            !isBigScreen
              ? { flexDirection: 'row' }
              : { flexDirection: 'column' }
          }
        >
          <div className="logo">
            <div className="header__branding">
              <Link to={'/'}>
                <h2>
                  Vlaamse
                  <br />
                  <b>Milieumaatschappij</b>
                </h2>
              </Link>
            </div>
            {isBigScreen && (
              <ul id="subNav">
                <li>
                  <UniversalLink href="/nieuws">Nieuws</UniversalLink>
                </li>
                <li>
                  <UniversalLink href="/evenementen">Evenementen</UniversalLink>
                </li>
                <li>
                  <UniversalLink href="/over-vmm">Over VMM</UniversalLink>
                </li>
                <li>
                  <UniversalLink href="/jobs">Jobs</UniversalLink>
                </li>
                <li>
                  <UniversalLink href="/publicaties">Publicaties</UniversalLink>
                </li>
                <li>
                  <UniversalLink href="/pers">Pers</UniversalLink>
                </li>
                <li>
                  <UniversalLink href="/contact">Contact</UniversalLink>
                </li>
              </ul>
            )}
          </div>
          {isBigScreen && <Navigation pathname={pathname} />}
          {!isBigScreen && <MobileNavigation pathname={pathname} />}
        </div>
      </Container>
    </div>
  );
};

const Header = (props) => {
  const { pathname } = props;
  let siteLabel = config.settings.siteLabel;
  const token = useSelector((state) => state.userSession.token);
  const siteAction = useSelector(
    (state) => state.content.data?.['@components']?.actions?.site_actions,
  );
  const intl = useIntl();
  const translatedSiteLabel = intl.formatMessage(messages.siteLabel);

  siteLabel =
    siteLabel &&
    (translatedSiteLabel !== 'siteLabel' && translatedSiteLabel !== ' '
      ? translatedSiteLabel
      : siteLabel);

  return (
    <header className={cx('header-wrapper')}>
      <InternetHeader
        pathname={pathname}
        siteLabel={siteLabel}
        token={token}
        siteAction={siteAction}
      />
    </header>
  );
};

Header.propTypes = {
  token: PropTypes.string,
  pathname: PropTypes.string.isRequired,
};

Header.defaultProps = {
  token: null,
};

export default Header;
