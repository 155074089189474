import cx from 'classnames';
import Tag from '.';

export const Tags = ({
  vertical,
  tags,
}: {
  vertical?: boolean;
  tags: undefined | string[];
}) =>
  tags &&
  !!tags.length && (
    <div className={cx('tags', vertical && 'tags--vertical')}>
      {tags.map((tag) => (
        <Tag>{tag?.split('>')?.pop()?.trim()}</Tag>
      ))}
    </div>
  );
