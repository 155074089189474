// SemanticUI-free pre-@plone/components
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import { defineMessages, injectIntl } from 'react-intl';
import { Container } from '@plone/components';
import './Breadcrumbs.scss';
import { getBreadcrumbs } from '@plone/volto/actions';
import { getBaseUrl, hasApiExpander } from '@plone/volto/helpers';

const MAX_CHARACTERS_BREADCRUMB_TEXT = 25;
const CHARACTER_ELIPSIS = '\u2026';

const messages = defineMessages({
  home: {
    id: 'Home',
    defaultMessage: 'Home',
  },
  breadcrumbs: {
    id: 'Breadcrumbs',
    defaultMessage: 'Breadcrumbs',
  },
});

/**
 * Breadcrumbs container class.
 */
export class BreadcrumbsComponent extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    getBreadcrumbs: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    root: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ).isRequired,
  };

  componentDidMount() {
    if (!hasApiExpander('breadcrumbs', getBaseUrl(this.props.pathname))) {
      this.props.getBreadcrumbs(getBaseUrl(this.props.pathname));
    }
  }

  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      if (!hasApiExpander('breadcrumbs', getBaseUrl(this.props.pathname))) {
        this.props.getBreadcrumbs(getBaseUrl(nextProps.pathname));
      }
    }
  }

  getEllipsedText(text) {
    if (text?.length > MAX_CHARACTERS_BREADCRUMB_TEXT)
      return text.slice(0, MAX_CHARACTERS_BREADCRUMB_TEXT) + CHARACTER_ELIPSIS;
    return text;
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    return (
      <div
        role="navigation"
        aria-label={this.props.intl.formatMessage(messages.breadcrumbs)}
        className="breadcrumbs"
      >
        <Container layout>
          <div className="breadcrumb">
            <Link
              to={this.props.root || '/'}
              className="home"
              title={this.props.intl.formatMessage(messages.home)}
            >
              <svg
                width="33"
                height="33"
                viewBox="0 0 33 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 5C0 2.23858 2.23858 0 5 0H28C30.7614 0 33 2.23858 33 5V16.5V28C33 30.7614 30.7614 33 28 33H5C2.23858 33 0 30.7614 0 28V5Z"
                  fill="#0C3543"
                />
                <path
                  d="M23 16.44C23.001 16.3012 22.973 16.1637 22.9179 16.0363C22.8628 15.9089 22.7818 15.7944 22.68 15.7L16.5 10L10.32 15.7C10.2182 15.7944 10.1372 15.9089 10.0821 16.0363C10.0271 16.1637 9.99908 16.3012 10 16.44V22C10 22.2652 10.1054 22.5196 10.2929 22.7071C10.4805 22.8946 10.7348 23 11 23H22C22.2652 23 22.5196 22.8946 22.7071 22.7071C22.8947 22.5196 23 22.2652 23 22V16.44Z"
                  stroke="white"
                  strokeLinewidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
            {this.props.items.map((item, index, items) => [
              <svg
                width="5"
                height="9"
                viewBox="0 0 5 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 7.5L3.89433 4.73333C3.92769 4.70341 3.95427 4.66726 3.97245 4.62713C3.99062 4.587 4 4.54373 4 4.5C4 4.45627 3.99062 4.413 3.97245 4.37287C3.95427 4.33274 3.92769 4.29659 3.89433 4.26667L1 1.5"
                  stroke="#1D9BF0"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>,
              index < items.length - 1 ? (
                <Link key={item.url} to={item.url} className="section">
                  {this.getEllipsedText(item.title)}
                </Link>
              ) : (
                <div className="section active" key={item.url}>
                  {this.getEllipsedText(item.title)}
                </div>
              ),
            ])}
          </div>
        </Container>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      items: state.breadcrumbs.items,
      root: state.breadcrumbs.root,
    }),
    { getBreadcrumbs },
  ),
)(BreadcrumbsComponent);
