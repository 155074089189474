import type { BlockConfigBase, JSONSchema } from '@plone/types';
import {
  defaultBackgroundColors,
  defaultStylingSchema,
} from '../../../../../../addons/volto-hoofdsite/src/helpers/stylingSchemaEnhancer';
import { gridWrapSchemaEnhancer } from '../../../helpers/gridWrapSchemaEnhancer';
import { ProjectListingTemplate } from './ProjectListingTemplate';
import { WhatsNewTemplate } from './WhatsNewTemplate';
import { addStyling, composeSchema } from '@plone/volto/helpers';
import DefaultListingBlockTemplate from '@plone/volto/components/manage/Blocks/Listing/DefaultTemplate';
import { CardListingTemplate } from './CardListing';
import { TeaserListingTemplate } from './TeaserListingTemplate';

export const listing = (
  config: BlockConfigBase & {
    colors: typeof defaultBackgroundColors;
    variations: {
      title: string;
      id: string;
      template: any;
      schemaEnhancer?: any;
    }[];
  },
) => {
  const schemaEnhancer = ({
    schema,
  }: {
    schema: JSONSchema & { properties: { [key: string]: any } };
  }) => {
    schema.properties.headlineLink = {
      title: 'Hoofding link',
      widget: 'object_browser',
      mode: 'link',
    };
    schema.properties.headlineLinkLabel = {
      title: 'Hoofding link',
    };

    const headlineIndex = schema.fieldsets[0].fields.findIndex(
      (f) => f === 'headline',
    );
    schema.fieldsets[0].fields.splice(
      headlineIndex + 1,
      0,
      'headlineLinkLabel',
    );
    schema.fieldsets[0].fields.splice(headlineIndex + 1, 0, 'headlineLink');

    schema.properties.querystring.default = {
      sort_on: 'effective',
      sort_order_boolean: 'descending',
    };

    return schema;
  };

  const updatedConfig = {
    ...config,
    colors: defaultBackgroundColors,
    schemaEnhancer: composeSchema(
      defaultStylingSchema,
      gridWrapSchemaEnhancer,
      schemaEnhancer,
    ),
  };

  updatedConfig.variations = [
    {
      title: 'Standaard teaser',
      id: 'teaser-listing',
      template: TeaserListingTemplate,
      isDefault: true,
    },
    updatedConfig.variations[0],
    {
      title: 'Basis',
      id: 'basic-listing',
      schemaEnhancer: ({
        schema,
        intl,
        formData,
      }: {
        schema: any;
        intl: any;
        formData: any;
      }) => {
        addStyling({ schema, intl, formData });
        schema.properties.styles.schema.properties.solid = {
          title: 'Gevuld',
          type: 'boolean',
        };
        schema.properties.styles.schema.fieldsets[0].fields.push('solid');
        return schema;
      },
      template: CardListingTemplate,
    },
    {
      title: 'VMM Pagina Samenvatting',
      id: 'vmm-article-listing',
      template: ProjectListingTemplate,
      isDefault: true,
    },
    {
      id: 'default',
      isDefault: false,
      title: 'Default',
      template: DefaultListingBlockTemplate,
    },
    {
      title: 'Wat is nieuw',
      id: 'whats-new',
      template: WhatsNewTemplate,
      schemaEnhancer: ({
        schema,
      }: {
        schema: JSONSchema & { properties: { [key: string]: object } };
      }) => {
        schema.properties.spotlightItem = {
          title: 'In de kijker',
          widget: 'object_browser',
          initialPath: '/nieuws',
          mode: 'link',
        };
        schema.properties.buttonLink = {
          title: 'Bekijk alle nieuws',
          widget: 'object_browser',
          mode: 'link',
        };
        const headlineIndex = schema.fieldsets[0].fields.findIndex(
          (f) => f === 'headlineLink',
        );
        const buttonLink = schema.fieldsets[0].fields.findIndex(
          (f) => f === 'buttonLink',
        );
        // Reminder: voegt velden toe aan fieldset
        schema.fieldsets[0].fields.splice(headlineIndex, 0, 'spotlightItem');
        schema.fieldsets[0].fields.splice(buttonLink, 0, 'buttonLink');
        return schema;
      },
    },
  ];

  return updatedConfig;
};
