/* eslint-disable jsx-a11y/anchor-is-valid */
import Container from '@kitconcept/volto-light-theme/components/Atoms/Container/Container';

export const SupportFooter = () => {
  return (
    <div className="support-footer footer">
      <Container layout className="support-footer__container">
        <h2>Heb je vragen?</h2>
        <ul>
          <li>
            Bekijk verzicht van <a href="#">meestgestelde vragen</a>.
          </li>
          <li>
            Niet gevonden wat je zocht? Vul ons <a href="#">contactformulier</a>{' '}
            in.
          </li>
          <li>
            <a href="tel:1700">Bel gratis 1700.</a>
          </li>
        </ul>
      </Container>
    </div>
  );
};
