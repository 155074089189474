import DRAUGHT from '../../../assets/draught.png';
import FLOOD from '../../../assets/flood.png';
import AIR_QUALITY from '../../../assets/air_quality.png';
import DRAUGHT_DARK from '../../../assets/draught_dark.png';
import FLOOD_DARK from '../../../assets/flood_dark.png';
import AIR_QUALITY_DARK from '../../../assets/air_quality_dark.png';

import WARNING_RED from '../../../assets/warning-red.svg';
import WARNING_YELLOW from '../../../assets/warning-yellow.svg';

import apiResponse from './api-response-example.json';

export type IndicatorStatus =
  | 'Zeer goed'
  | 'Goed'
  | 'Geen risico'
  | 'Normaal'
  | 'Verhoogd risico'
  | 'Slecht';
export type Indicator = {
  id: string;
  name: string;
  icon: string | null;
  iconDark: string | null;
  status: IndicatorStatus;
  futureStatus: IndicatorStatus | null;
  color: string;
  href: string;
};

export type Warning = {
  icon: string;
  label: string;
  href: string;
};

const map: {
  [name: string]: {
    name: string;
    icon: string | null;
    iconDark: string | null;
  };
} = {
  lucht: {
    name: 'Luchtkwaliteit',
    icon: AIR_QUALITY,
    iconDark: AIR_QUALITY_DARK,
  },
  overstroming: { name: 'Overstromingen', icon: FLOOD, iconDark: FLOOD_DARK },
  neerslag: { name: 'Neerslag', icon: null, iconDark: null },
  droogte: { name: 'Droogte', icon: DRAUGHT, iconDark: DRAUGHT_DARK },
};
export const indicators = Object.keys(map);
export const getIndicators = async () => {
  return apiResponse.value.reduce((list, v, i, arr) => {
    if (v.naam.indexOf('actueel') === -1) return list;

    const name = v.naam.replace('-actueel', '');
    const indicator: Indicator = {
      id: name,
      name: map[name]?.name || name,
      icon: map[name]?.icon,
      iconDark: map[name]?.iconDark,
      status: v.status_beschrijving as IndicatorStatus,
      futureStatus:
        (arr.find((v) => v.naam === `${name}-morgen`)
          ?.status_beschrijving as IndicatorStatus) || null,
      color: v.kleur,
      href: '#',
    };

    list.push(indicator);

    return list;
  }, [] as Indicator[]);
};

// export const indicators: Indicator[] = [
//   {
//     name: 'Lucht',
//     status: 'Goed',
//     color: '#00E789',
//     futureStatus: 'Zeer goed',
//     icon: AIR_QUALITY,
//     iconDark: AIR_QUALITY_DARK,
//     href: '#',
//   },
//   {
//     name: 'Overstroming',
//     status: 'Geen risico',
//     color: '#00E789',
//     futureStatus: 'Verhoogd risico',
//     icon: FLOOD,
//     iconDark: FLOOD_DARK,
//     href: '#',
//   },
//   {
//     name: 'Droogte',
//     status: 'Normaal',
//     color: '#20E5F1',
//     futureStatus: 'Normaal',
//     icon: DRAUGHT,
//     iconDark: DRAUGHT_DARK,
//     href: '#',
//   },
// ];

export const warnings: Warning[] = [
  {
    icon: WARNING_RED,
    label: 'Hoge concentraties stikstof (NO2) in Gent',
    href: '#',
  },
  {
    icon: WARNING_YELLOW,
    label: 'Droogtemaatregelen van toepassing',
    href: '#',
  },
];
