import { H2, Label, Text } from '../../Typography';
import { socials } from './socials';
import { Input } from '../../Inputs';
import { Button } from '../../Button';
import { useBreakpoint } from '../../../helpers/useMedia';

export const SocialsView = () => {
  const isBigScreen = useBreakpoint('lg');

  const socialsElements = (
    <div className="socials-block__links">
      <Text size="xs" weight="500" uppercase>
        Volg VMM op sociale media
      </Text>
      <div>
        {socials.map(([src, href, size]) => (
          <a href={href} key={src}>
            <img
              src={src}
              style={{
                width: size,
                height: size,
              }}
              alt="Social logo"
            />
          </a>
        ))}
      </div>
    </div>
  );

  return (
    <div className="block socials-block">
      <div className="container">
        <div>
          <H2>Blijf je graag op de hoogte?</H2>
          <Text size="lg">
            We maken maandelijks voor jou een selectie van de belangrijkste
            nieuwsberichten op maat van de milieuprofessional.
          </Text>

          {isBigScreen && socialsElements}
        </div>
        <div>
          <Text
            size="xs"
            weight="500"
            uppercase
            className="socials-block__news-letter-title"
          >
            Schrijf je in op de nieuwsbrief
          </Text>
          <form className="socials-block__news-letter-form">
            <Label htmlFor="#news-letter-input">
              E-mail
              <Input type="email" />
            </Label>
            <Button halfRounded size="large">
              Inschrijven
            </Button>
          </form>
          {!isBigScreen && socialsElements}
        </div>
      </div>
    </div>
  );
};
